/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { forwardRef, type ReactNode, type Ref } from 'react';

import { jsx, type XCSSProp } from '@compiled/react';

export type ListProps = {
	children: ReactNode;
	xcss?: XCSSProp<'alignItems' | 'display' | 'gap' | 'paddingInlineStart' | 'padding', never>;
};

function _List({ children, xcss }: ListProps, forwardedRef: Ref<HTMLDivElement>) {
	return (
		/**
		 * We are using `role="list"` instead of a `ul` element to enable more flexible
		 * composition. By using ARIA roles we can validly have elements between a list
		 * and list items, as long as those in-between elements have no semantics.
		 */
		<div role="list" ref={forwardedRef} className={xcss}>
			{children}
		</div>
	);
}

/**
 * __List__
 *
 * A an element with the role of `list` for semantically grouping list items.
 *
 * This is the internal primitive used by other external components in the navigation system.
 */
export const List = forwardRef<HTMLDivElement, ListProps>(_List);
