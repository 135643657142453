import React from 'react';
import { Coordination } from '@atlassiansox/engagekit-ts';

import { LoadableLazy } from '@confluence/loadable';
import { useCoordinationClient } from '@confluence/engagement-provider';
import { fg } from '@confluence/feature-gating';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { ErrorDisplay } from '@confluence/error-boundary';

import type { Nav4ChangeboardingSpotlightProps } from './Nav4ChangeboardingSpotlight';
import { useHasDismissedNav4Modal } from './useHasDismissedNav4Modal';

const Nav4ChangeboardingSpotlightLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-Nav4ChangeboardingSpotlight" */ './Nav4ChangeboardingSpotlight'
			)
		).Nav4ChangeboardingSpotlight,
});

export const CoordinatedNav4ChangeboardingSpotlight = ({
	activeNav4Spotlight,
	setActiveNav4Spotlight,
}: Nav4ChangeboardingSpotlightProps) => {
	const coordinationClient = useCoordinationClient(false);
	const isNav4Enabled = useIsNav4Enabled();
	const { hasDismissedNav4Modal, loading, error } = useHasDismissedNav4Modal();

	if (
		!isNav4Enabled ||
		!hasDismissedNav4Modal ||
		loading ||
		!fg('confluence_nav_4_changeboarding')
	) {
		return null;
	}

	return (
		<>
			<Coordination client={coordinationClient} messageId={getChangeboardingMessageId()}>
				<Nav4ChangeboardingSpotlightLoader
					activeNav4Spotlight={activeNav4Spotlight}
					setActiveNav4Spotlight={setActiveNav4Spotlight}
				/>
			</Coordination>
			{error && <ErrorDisplay error={error} />}
		</>
	);
};

export const getChangeboardingMessageId = () =>
	fg('confluence_nav_4_changeboarding_message')
		? 'cc-nav-4-changeboarding-prod'
		: 'cc-nav-4-changeboarding';
