import type { RefObject } from 'react';
import { createContext, useContext } from 'react';

type SideNavigationContextType = {
	sideNavRef: RefObject<HTMLDivElement>;
	handleScrollToTop: () => void;
};

export const SideNavigationContext = createContext<SideNavigationContextType | null>(null);

export const useSideNavigation = () => {
	const context = useContext(SideNavigationContext);
	if (!context) {
		throw new Error('useSideNavigation must be used within a SideNavigationContext.Provider');
	}
	return context;
};
