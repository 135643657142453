import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';

import type {
	AnonymousAccessSpaceQuery as AnonymousAccessSpaceQueryType,
	AnonymousAccessSpaceQueryVariables,
} from './__types__/AnonymousAccessSpaceQuery';

export const useIsUserViewingSpaceAsAnon = ({
	spaceKey,
	skipQuery,
}: {
	spaceKey: string;
	skipQuery?: boolean;
}) => {
	const { data, loading, error } = useQuery<
		AnonymousAccessSpaceQueryType,
		AnonymousAccessSpaceQueryVariables
	>(
		gql`
			query useIsUserViewingSpaceAsAnonQuery($spaceKey: String) {
				space(key: $spaceKey) {
					id
					currentUser {
						isAnonymouslyAuthorized
					}
				}
			}
		`,
		{
			variables: { spaceKey },
			skip: skipQuery || !spaceKey,
		},
	);

	return {
		isUserViewingSpaceAsAnon: Boolean(data?.space?.currentUser?.isAnonymouslyAuthorized),
		loading,
		error,
	};
};
