import { type EffectsDispatcher } from './effect';
import {
	type Handler,
	type ExtensionData,
	type ExtensionPayload,
	type AKOption,
	type ForgeDoc,
} from '../public';
import { type ReactElement } from 'react';

type AllFunctionPropertyNames<T> = {
	[K in keyof T]: Required<T>[K] extends Function ? K : never;
}[keyof T];

export type Rendered<ComponentProps extends object> = {
	[K in keyof ComponentProps]: K extends AllFunctionPropertyNames<ComponentProps>
		? Handler
		: K extends 'children'
			? React.ReactNode
			: ComponentProps[K];
};

export type DispatchEffect =
	| {
			type: 'event';
			handler: Handler;
			args: any[];
			extensionData: ExtensionData;
			/**
			 * All dynamic contextual data for the extension should be passed in here,
			 * rather than in the context (extensionData).
			 * This will prevent it being included in the Forge Context Token claims.
			 */
			extensionPayload?: ExtensionPayload;
	  }
	| {
			type: 'render';
			extensionData: ExtensionData;
			/**
			 * All dynamic contextual data for the extension should be passed in here,
			 * rather than in the context (extensionData).
			 * This will prevent it being included in the Forge Context Token claims.
			 */
			extensionPayload?: ExtensionPayload;
	  };

export type Dispatch = (effect: DispatchEffect) => Promise<void>;

export type WithDispatch<ComponentProps extends object> = Omit<ComponentProps, 'dispatch'> & {
	dispatch: EffectsDispatcher;
};

export interface CheckboxOption extends AKOption {
	key?: string;
	defaultChecked?: boolean;
	isRequired?: boolean;
}

export interface RadioOption extends AKOption {
	name: string;
	value: string;
}

export const MarkupRecursiveList = ['Em', 'Link', 'Strike', 'String', 'Strong', 'User'] as const;

export const MarkupList = [
	...MarkupRecursiveList,
	'Badge',
	'Code',
	'DateLozenge',
	'StatusLozenge',
	'Tag',
	'UserGroup',
] as const;

export type Render = (forgeDoc: ForgeDoc) => ReactElement;

export type RenderFn = (args: {
	forgeDoc: ForgeDoc;
	dispatch: Dispatch;
	render: Render;
}) => ReactElement;
