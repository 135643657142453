import React, { useState, useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { Flex } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/utility/cross';

import { SideNavFooter } from '@atlassian/navigation-system/layout/side-nav';

import { useSpaceKey } from '@confluence/space-utils';

export const AnonymousAccessSideNavMessage = () => {
	const [closedMessage, setClosedMessage] = useState(false);
	const intl = useIntl();
	const spaceKey = useSpaceKey();

	useEffect(() => {
		if (closedMessage) {
			setClosedMessage(false);
		}
		// Should only fire when spaceKey changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [spaceKey]);

	if (closedMessage) {
		return null;
	}

	return (
		<SideNavFooter>
			<Flex justifyContent="space-between">
				<Flex alignItems="center">
					<FormattedMessage {...i18n.anonymousAccessMessage} />
				</Flex>
				<IconButton
					onClick={() => setClosedMessage(true)}
					icon={CrossIcon}
					label={intl.formatMessage(i18n.close)}
					appearance="subtle"
					spacing="compact"
				/>
			</Flex>
		</SideNavFooter>
	);
};

const i18n = defineMessages({
	anonymousAccessMessage: {
		id: 'anonymous-access.anonymous-access-side-nav-message.text',
		// TODO: Fix the direction of the curly quote below. (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: 'You‘re viewing this with anonymous access, so some content might be blocked.',
		description:
			'Text for anonymous access message in the side navigation footer, which is shown only to users with anonymous access',
	},
	close: {
		id: 'anonymous-access.anonymous-access-side-nav-message.close',
		defaultMessage: 'Close',
		description:
			'Label for the close button for anonymous access message in the side navigation footer, which is shown only to users with anonymous access ',
	},
});
