export const ANALYTICS_KEY =
	'com.atlassian.plugins.atlassian-connect-plugin:space-navigation-web-item-com-addonengine-analytics-space-analytics';

export const ANALYTICS_KEY_MODERATE =
	'com.atlassian.plugins.atlassian-connect-plugin:space-navigation-web-item-com-addonengine-analytics-moderate-space-analytics';

export const AUTOMATION_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:spacebar-automation';

export const BLOG_KEY = 'com.atlassian.confluence.plugins.confluence-space-ia:spacebar-blogs';

export const CALENDARS_KEY =
	'com.atlassian.confluence.extra.team-calendars:space-calendar-sidebar-link';

export const DATABASES_KEY =
	'com.atlassian.plugins.atlassian-connect-plugin:space-navigation-web-item-com-k15t-orderly-databases-database';

export const QUESTIONS_KEY =
	'com.atlassian.plugins.atlassian-connect-plugin:space-navigation-web-item-com-atlassian-confluence-plugins-confluence-questions-question';

export const QUESTIONS_NATIVE_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:spacebar-questions';

export const SPACE_SETTINGS_KEY =
	'com.atlassian.confluence.plugins.confluence-frontend-support:space-tools-link';

export const CONNECT_ADDON_KEY = 'com.atlassian.plugins.atlassian-connect-plugin';

export const SHORTCUTS_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:spacebar-shortcuts';

export const APPS_KEY = 'com.atlassian.confluence.plugins.confluence-space-ia:spacebar-apps';

export const BULK_TOOLS_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:spacebar-content-manager';
