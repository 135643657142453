import { SPACE_FORGE_APPS } from '@confluence/named-routes';
import { extensionTitle, type ForgeUISpacePageExtensionType } from '@confluence/forge-ui';

import {
	ANALYTICS_KEY,
	ANALYTICS_KEY_MODERATE,
	QUESTIONS_KEY,
	CONNECT_ADDON_KEY,
	DATABASES_KEY,
	APPS_KEY,
} from './webItemCompleteKeys';
import type { SpaceNavigationQuery_spaceSidebarLinks_main } from './__types__/SpaceNavigationQuery';
import { SpaceSidebarLinkType } from './__types__/BulkUpdateMainSpaceSidebarLinks';

export const getAllAppLinks = (
	spaceKey: string,
	links: SpaceNavigationQuery_spaceSidebarLinks_main[],
	forgeApps: ForgeUISpacePageExtensionType[],
	isSpaceAdmin: boolean,
) => {
	const forgeAppsMap: Map<string, ForgeUISpacePageExtensionType> = forgeApps.reduce((acc, app) => {
		return acc.set(app.id, app);
	}, new Map());

	const allAppLinks = links
		.filter(
			(link) =>
				(isConnectApp(link) && !isBuiltInItem(link)) ||
				(isForgeApp(link) && forgeAppsMap.has(link.linkIdentifier as string)),
		)
		// Fill in links for Forge apps
		.map((link) => {
			if (!isForgeApp(link)) {
				return link;
			}
			const forgeApp = forgeAppsMap.get(
				link.linkIdentifier as string,
			) as ForgeUISpacePageExtensionType;

			return {
				...link,
				url: getForgeURL(spaceKey, forgeApp),
				title: extensionTitle(forgeApp),
				icon: {
					width: null,
					height: null,
					isDefault: null,
					path: forgeApp.properties.icon || '',
				},
			};
		})
		.sort((a, b) => Number(a.position) - Number(b.position));

	// Adding forge apps that are not in the links yet
	// This could be possible for new installed forge apps
	const allForgeLinksIds = allAppLinks.filter(isForgeApp).map((link) => link.linkIdentifier);

	forgeApps.forEach((forgeApp) => {
		if (!allForgeLinksIds.includes(forgeApp.id)) {
			const forgeAppLink = {
				id: forgeApp.id,
				type: SpaceSidebarLinkType.FORGE,
				linkIdentifier: forgeApp.id,
				position: 0,
				hidden: false,
				webItemKey: null,
				webItemCompleteKey: null,
				title: extensionTitle(forgeApp),
				url: getForgeURL(spaceKey, forgeApp),
				styleClass: null,
				icon: {
					path: forgeApp.properties.icon || '',
					width: null,
					height: null,
					isDefault: null,
				},
				iconClass: null,
				canHide: null,
				tooltip: null,
				properties: forgeApp.properties,
			};
			allAppLinks.push(forgeAppLink);
		}
	});

	const appsHidden =
		links.filter(({ webItemCompleteKey, hidden }) => webItemCompleteKey === APPS_KEY && hidden)
			.length > 0;

	const allVisibleAppLinks = allAppLinks.filter((link) => !link.hidden);
	const shouldRenderSpaceApps =
		!appsHidden && !(allAppLinks.length == 0 || (allVisibleAppLinks.length == 0 && !isSpaceAdmin));
	return {
		allAppLinks,
		allVisibleAppLinks,
		shouldRenderSpaceApps,
	};
};

export const getForgeURL = (spaceKey: string, app: ForgeUISpacePageExtensionType): string => {
	return SPACE_FORGE_APPS.toUrl({
		spaceKey,
		appId: app.appId,
		envId: app.environmentId,
		forgeManifestRoute: app.properties.route,
	});
};

export const isConnectApp = (link) => {
	return link.webItemCompleteKey?.startsWith(CONNECT_ADDON_KEY);
};

export const isForgeApp = (link): boolean => {
	return link.type === SpaceSidebarLinkType.FORGE && link.linkIdentifier;
};

const isBuiltInItem = (link): boolean => {
	const builtInItems = [ANALYTICS_KEY, ANALYTICS_KEY_MODERATE, QUESTIONS_KEY, DATABASES_KEY];

	return builtInItems.includes(link.webItemCompleteKey);
};
