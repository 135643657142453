import React, { forwardRef, type ReactNode } from 'react';

import useControlled from '@atlaskit/ds-lib/use-controlled';

import { MenuListItem } from '../menu-list-item';

import {
	IsExpandedContext,
	OnExpansionToggleContext,
	SetIsExpandedContext,
} from './expandable-menu-item-context';

export type ExpandableMenuItemProps = {
	isExpanded?: boolean;
	isDefaultExpanded?: boolean;
	/**
	 * Called when the user has interacted with the menu item to expand or collapse it.
	 *
	 * It is not called when the `isExpanded` controlled state prop is changed.
	 */
	onExpansionToggle?: (isExpanded: boolean) => void;
	children: ReactNode;
};

/**
 * __ExpandableMenuItem__
 *
 * A composable component for nested menu items that can be revealed and hidden by interacting witih the parent menu item.
 *
 * Follows the [disclosure pattern](https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/).
 *
 * Should be used with `ExpandableMenuItemTrigger` as the parent menu item, and children menu items should be wrapped in a `ExpandableMenuItemContent`.
 *
 * Usage example:
 * ```jsx
 * <ExpandableMenuItem>
 *   <ExpandableMenuItemTrigger>Parent menu item</ExpandableMenuItemTrigger>
 *   <ExpandableMenuItemContent>
 *     <MenuButtonItem>Item 1</MenuButtonItem>
 *     <MenuButtonItem>Item 2</MenuButtonItem>
 *   </ExpandableMenuItemContent>
 * </ExpandableMenuItem>
 * ```
 */
export const ExpandableMenuItem = forwardRef<HTMLDivElement, ExpandableMenuItemProps>(
	(
		{ isExpanded: isExpandedControlled, isDefaultExpanded = false, onExpansionToggle, children },
		forwardedRef,
	) => {
		const [isExpanded, setIsExpanded] = useControlled(
			isExpandedControlled,
			() => isDefaultExpanded,
		);

		return (
			<IsExpandedContext.Provider value={isExpanded}>
				<SetIsExpandedContext.Provider value={setIsExpanded}>
					<OnExpansionToggleContext.Provider value={onExpansionToggle ?? null}>
						{/* Wrapping in a `li` to group all the composable elements together, as part of the disclosure pattern */}
						<MenuListItem ref={forwardedRef}>{children}</MenuListItem>
					</OnExpansionToggleContext.Provider>
				</SetIsExpandedContext.Provider>
			</IsExpandedContext.Provider>
		);
	},
);
