import type { FC } from 'react';
import React, { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { LinkItem } from '@atlaskit/side-navigation';
import Lozenge from '@atlaskit/lozenge';
import Tooltip from '@atlaskit/tooltip';
import SmartLinkListIcon from '@atlaskit/icon/core/smart-link-list';

import { usePageSpaceKey } from '@confluence/page-context';
import type { DATABASE_CUSTOM_OVERVIEW } from '@confluence/named-routes';

import { cssFn } from './LegacySpaceLinks';

const i18n = defineMessages({
	databases: {
		id: 'side-navigation.databases.word',
		defaultMessage: 'Databases',
		description: 'Label for the button which navigates the user to their Databases tab',
	},
	alpha: {
		id: 'side-navigation.databases.alpha-label',
		defaultMessage: 'Alpha',
		description: "Label next to the 'Databases' link, indicating that the feature is in alpha.",
	},
	tooltip: {
		id: 'side-navigation.databases.tooltip',
		defaultMessage: 'View all databases in this space',
		description: 'Tooltip message on hover of Databases space link',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomItemDiv = styled.div({
	position: 'relative',
	borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.span({
	display: 'inline-block',
	marginLeft: token('space.100'),
});

type DatabasesNavigationProps = {
	isSelected: boolean;
	onClick?: () => void;
	databasesRoute: typeof DATABASE_CUSTOM_OVERVIEW;
};

const DatabasesNavigationComponent: FC<DatabasesNavigationProps> = ({
	isSelected,
	onClick,
	databasesRoute,
}) => {
	const intl = useIntl();
	const [spaceKey] = usePageSpaceKey();

	return (
		<CustomItemDiv>
			<Tooltip position="top" content={intl.formatMessage(i18n.tooltip)}>
				{(tooltipProps) => (
					<li>
						<LinkItem
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={() => cssFn({ isDatabaseIcon: true, isSelected })}
							iconBefore={<SmartLinkListIcon label="" spacing="spacious" />}
							isSelected={isSelected}
							href={databasesRoute.toUrl({ spaceKey })}
							{...tooltipProps}
							onClick={onClick}
							testId="space-navigation-databases-link"
						>
							{intl.formatMessage(i18n.databases)}
							<LozengeWrapper>
								<Lozenge appearance="new">{intl.formatMessage(i18n.alpha)}</Lozenge>
							</LozengeWrapper>
						</LinkItem>
					</li>
				)}
			</Tooltip>
		</CustomItemDiv>
	);
};

export const DatabasesNavigation = memo(DatabasesNavigationComponent);
