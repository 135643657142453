import { useQuery } from 'react-apollo';

import { useSessionData } from '@confluence/session-data';
import { getApolloClient } from '@confluence/graphql';

import { Nav4ChangeboardingQuery } from './Nav4ChangeboardingQuery.graphql';
import type {
	Nav4ChangeboardingQuery as Nav4ChangeboardingQueryData,
	Nav4ChangeboardingQueryVariables,
} from './__types__/Nav4ChangeboardingQuery';

const CONFLUENCE_MODAL_DISMISSED_TRAIT =
	'confluence_ui_nav4globalonboardingmodal_dismissed_confluenceatlassianonboardingnav4_changed';

const MODAL_DISMISSED_TRAITS = [
	// Confluence
	CONFLUENCE_MODAL_DISMISSED_TRAIT,
	// Jira
	'jira-platform_ui_nav4globalonboardingmodal_dismissed_jiraatlassianonboardingnav4_changed',
	// Home
	'townsquare_ui_atlassianhomeonboardingnav4_dismissed_teamcentral_changed',
];

/**
 * Returns whether the user has seen the Nav4 onboarding modal in Confluence, Jira, or Home.
 */
export const useHasDismissedNav4Modal = () => {
	const { userId } = useSessionData();
	const { data, loading, error } = useQuery<
		Nav4ChangeboardingQueryData,
		Nav4ChangeboardingQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		Nav4ChangeboardingQuery,
		{
			variables: { userId: userId || '' },
			skip: !userId,
		},
	);

	const hasDismissedNav4Modal = getHasDismissedNav4Modal(data);

	return {
		hasDismissedNav4Modal,
		loading,
		error,
	};
};

const getHasDismissedNav4Modal = (data: Nav4ChangeboardingQueryData | undefined) => {
	const attributes = data?.experimentalUserPersonalization?.attributes;

	if (!attributes) {
		return false;
	}

	return attributes.some(
		({ name, value }) => value === 'true' && MODAL_DISMISSED_TRAITS.includes(name),
	);
};

export const setHasDismissedNav4Modal = (userId: string) => {
	const apolloClient = getApolloClient();

	let existingData: Nav4ChangeboardingQueryData | null | undefined;
	try {
		apolloClient.readQuery<Nav4ChangeboardingQueryData, Nav4ChangeboardingQueryVariables>({
			query: Nav4ChangeboardingQuery,
			variables: { userId },
		});
	} catch (_) {
		// This error is expected if the original query failed for some reason.
		// It is being ignored as it does not impact functionality.
	}

	const newData: Nav4ChangeboardingQueryData = {
		experimentalUserPersonalization: {
			attributes: [
				...(existingData?.experimentalUserPersonalization?.attributes || []),
				{
					name: CONFLUENCE_MODAL_DISMISSED_TRAIT,
					value: 'true',
					__typename: 'ExperimentalUserPersonalizationAttributes',
				},
			],
			__typename: 'ExperimentalUserPersonalization',
		},
	};

	apolloClient.writeQuery({
		query: Nav4ChangeboardingQuery,
		variables: { userId },
		data: newData,
	});
};
