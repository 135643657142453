export type ContextId = string;
export type ExtensionId = string;

export interface ExtensionInstanceIds {
	contextIds: ContextId[];
	extensionId: ExtensionId;
	localId: string;
	functionId?: string;
}

export enum ExtensionEnvironment {
	DEVELOPMENT = 'DEVELOPMENT',
	STAGING = 'STAGING',
	PRODUCTION = 'PRODUCTION',
}

export type EnvironmentType = keyof typeof ExtensionEnvironment;
export type ViewportSizeType = 'small' | 'medium' | 'large' | 'xlarge' | 'max';
export type LayoutType = 'inline' | 'block' | 'bodied';

type AutoConvertMatches = {
	pattern: string;
};

export interface ForgeUIExtensionType {
	id: string;
	/** Module key */
	key?: string;
	installationId?: string;
	appId?: string;
	environmentId?: string;
	properties: {
		autoConvert?: { matchers: Array<AutoConvertMatches> };
		title?: string;
		description?: string;
		icon?: string;
		categories?: string[];
		resource?: string;
		viewportSize?: ViewportSizeType;
		config?: {
			// UI Kit 1
			function?: string;
			// UI Kit 2 / Custom UI
			resource?: string;
			render?: 'default' | 'native';
			title?: string;
			viewportSize?: ViewportSizeType;
			openOnInsert?: boolean;
		};
		render?: 'default' | 'native';
		layout?: LayoutType;
	};
	appOwner?: {
		accountId: string;
		name: string;
		picture: string;
	};
	appVersion?: string;
	environmentType?: EnvironmentType;
	environmentKey?: string;
	type?: string; // e.g. confluence:contentAction, confluence:contextMenu
	consentUrl?: string;
	currentUserConsent?: {
		user: {
			aaid: string;
		};
		appEnvironmentVersion: {
			id: string;
		};
		consentedAt: string;
	};
	requiresUserConsent?: boolean;
	migrationKey?: string;
	dataClassificationPolicyDecision?: {
		status: string;
	};
}

interface ADFEntityMark {
	type: string;
	attrs?: { [name: string]: any };
}

interface ADFEntity {
	type: string;
	attrs?: { [name: string]: any };
	content?: Array<ADFEntity | undefined>;
	marks?: Array<ADFEntityMark>;
	text?: string;
	[key: string]: any;
}

// duplicated ReferenceEntity from @atlaskit/editor-common to avoid adding @atlaskit/editor-common as a dependency
export type ReferenceEntity = {
	[prop: string]: ADFEntity | Object;
};

export const ConfluenceExtension = {
	BYLINE: 'confluence:contentBylineItem',
	CONTEXT_MENU: 'confluence:contextMenu',
	CONTENT_ACTION: 'confluence:contentAction',
	GLOBAL_PAGE: 'confluence:globalPage',
	GLOBAL_SETTINGS: 'confluence:globalSettings',
	HOMEPAGE_FEED: 'confluence:homepageFeed',
	SPACE_PAGE: 'confluence:spacePage',
	SPACE_SETTINGS: 'confluence:spaceSettings',
	CUSTOM_CONTENT: 'confluence:customContent',
	TEST_MODULE: 'test:module',
} as const;

export type ConfluenceExtensionType =
	(typeof ConfluenceExtension)[keyof typeof ConfluenceExtension];

export const JiraExtension = {
	BACKLOG_ACTION: 'jira:backlogAction',
	BOARD_ACTION: 'jira:boardAction',
	PERSONAL_SETTINGS_PAGE: 'jira:personalSettingsPage',
	ISSUE_PANEL: 'jira:issuePanel',
	ISSUE_ACTION: 'jira:issueAction',
	ISSUE_GLANCE: 'jira:issueGlance',
	ISSUE_CONTEXT: 'jira:issueContext',
	ISSUE_ACTIVITY: 'jira:issueActivity',
	UI_MODIFICATIONS: 'jira:uiModifications',
	CUSTOM_FIELD: 'jira:customField',
	CUSTOM_FIELD_TYPE: 'jira:customFieldType',
	ADMIN_PAGE: 'jira:adminPage',
	GLOBAL_PAGE: 'jira:globalPage',
	PROJECT_PAGE: 'jira:projectPage',
	PROJECT_SETTINGS_PAGE: 'jira:projectSettingsPage',
	DASHBOARD_BACKGROUND_SCRIPT: 'jira:dashboardBackgroundScript',
	ISSUE_VIEW_BACKGROUND_SCRIPT: 'jira:issueViewBackgroundScript',
	DASHBOARD_GADGET: 'jira:dashboardGadget',
	WORKFLOW_VALIDATOR: 'jira:workflowValidator',
	WORKFLOW_CONDITION: 'jira:workflowCondition',
	WORKFLOW_POST_FUNCTION: 'jira:workflowPostFunction',
	SERVICE_DESK_QUEUE_PAGE: 'jiraServiceManagement:queuePage',
	SERVICE_DESK_ORGANIZATION_PANEL: 'jiraServiceManagement:organizationPanel',
} as const;

export type JiraExtensionType = (typeof JiraExtension)[keyof typeof JiraExtension];

export type ForgeExtensionPoints = JiraExtensionType | ConfluenceExtensionType;
